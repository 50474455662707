import React, { Fragment, Component } from "react";
import { StaticQuery, graphql, withPrefix } from "gatsby";
import GatsbyLink from "../../GatsbyLink";
import { decodeEntities } from "../../../utils/helpers";
import { ViewMorePosts } from "../ViewMorePosts";
import "./SearchResultsList.scss";
import RenderContent from "../../RenderContent";

class SearchResultsList extends Component {
  render() {
    const {
      visibleItems,
      allMatchingItems,
      totalItems,
      showMorePostsHandler,
      filterItems,
      activeFilter,
      data,
    } = this.props;

    // Handle no search results
    if (!visibleItems || visibleItems.length < 1) return null;
    const { siteUrl } = data.wordpressWpSettings;
    // Get result filters using result type (see gatsby-config.js)
    const uniqueResultFilters = new Set(
      allMatchingItems.map((result) => result.type)
    );
    const resultFilters = Array.from(uniqueResultFilters);
    return (
      <Fragment>
        {/* {resultFilters && (
          <div className="result-filters">
            <div className="wrap">
              <div className="inner">
                <strong>Filter by: </strong>
                <span
                  className={`filter ${activeFilter === 0 ? "active" : ""}`}
                  onClick={() => filterItems(0)}
                >
                  All
                </span>
                {resultFilters.map((filter, index) => {
                  return (
                    <span
                      key={index}
                      className={`filter ${
                        index + 1 === activeFilter ? "active" : ""
                      }`}
                      onClick={() => filterItems(index + 1, filter)}
                    >
                      {filter}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        )} */}
        <div className="results">
          <div className="wrap">
            <div className="list">
              {visibleItems.map((result) => {
                const { type } = result;
                const parentPage = result.parentPage || undefined;
                const dateOptions = {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                };
                const formattedDate = new Date(result.date)
                  .toLocaleDateString("en-US", dateOptions)
                  .replace(/\//g, ".");
                // const mainCategory = result.categories ? result.categories[0] : null;
                const resultSlug = `${
                  parentPage ? `/${parentPage.node.slug}` : ""
                }/${result.slug}`;

                return (
                  <div className="result" key={result.slug}>
                    <div>
                      <GatsbyLink to={`${resultSlug}/`}>
                        <div className="inner">
                          <div className="results-content">
                            <h4 className="title">
                              {decodeEntities(result.title)}
                            </h4>
                            {result.excerpt && (
                              <RenderContent content={result.excerpt} />
                            )}
                          </div>

                          {/* <div className="meta">
                            {formattedDate && (
                              <div className="date">{formattedDate}</div>
                            )}
                            {type && <div className="type">{type}</div>}
                          </div> */}
                        </div>
                      </GatsbyLink>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ViewMorePosts
          currentItems={visibleItems.length}
          totalItems={totalItems}
          showMorePostsHandler={showMorePostsHandler}
        />
      </Fragment>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={(data) => <SearchResultsList data={data} {...props} />}
  />
);
